import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './Projects.css';
import Project1 from './Project1';
import Project2 from './Project2';
import Project3 from './Project3';
import Project4 from './Project4';
import Project5 from './Project5';
import { Link } from 'react-router-dom';
import { Card, Carousel } from 'react-bootstrap';

const projectsData = [
    {
        id: 1,
        name: 'Project 1',
        imageUrl: '/images/project2.png',
        path: '/mario',
        label: "Super Mario Bros Simulator",
        component: Project2,
    },

  
    {
        id: 2,
        name: 'Project 2',
        imageUrl: '/images/project3.png',
        path: '/palindromes',
        label: "Genetic Palindromes Analysis",
        component: Project3,
    },
    {
        id: 3,
        name: 'Project 4',
        imageUrl: '/images/project4.png',
        path: '/studentfacultydatabase',
        label: "Student/Faculty Database System",
        component: Project4,
    },
    {
        id: 4,
        name: 'Project 4',
        imageUrl: '/images/project5.png',
        path: '/spoons',
        label: "Spoons Simulator",
        component: Project5,
    },
    {
        id: 5,
        name: 'Project 5',
        imageUrl: '/images/project1.png',
        path: '/portfolio',
        label: "Digital Portfolio",
        component: Project1,
    },
];

const Projects = () => {
    // const navigate = useNavigate();
    // const [currentIndex, setCurrentIndex] = useState(0);

    // const handleProjectClick = (path) => {
    //     navigate(path);
    // };

    // const handleSelect = (selectedIndex, e) => {
    //     setCurrentIndex(selectedIndex);
    // };

    // const prevProject = () => {
    //     setCurrentIndex((prevIndex) => (prevIndex === 0 ? projectsData.length - 1 : prevIndex - 1));
    // };

    // const nextProject = () => {
    //     setCurrentIndex((prevIndex) => (prevIndex === projectsData.length - 1 ? 0 : prevIndex + 1));
    // };

    return (
        // <div id="projects" className="projects-container">
        //     <h2 style={{ fontSize: '36px', color: '#0097b2' }}>Projects</h2>
        //     <div className="project-gallery">
        //         <div className="projects-grid">
        //             <button onClick={prevProject} className="prev-btn"><i className="fa-solid fa-angle-left"></i></button>
        //             {projectsData.map((project) => (
        //                 <div key={project.id} className="project-container" style={{ transform: `translateX(-${100 * currentIndex}%)` }} onClick={() => handleProjectClick(project.path)}>
        //                     {/* <div className='project-label'><h4><u>{project.label}</u></h4></div> */}

        //                     <div className="project-link">
        //                         <img
        //                             src={project.imageUrl}
        //                             alt={`Project ${project.id}`}
        //                             className="project-image"
        //                         />
        //                     </div>
        //                 </div>
        //             ))}
        //             <button onClick={nextProject} className="next-btn"><i className="fa-solid fa-angle-right"></i></button>
        //         </div>
        //         <div style={{
        //             position: "absolute",
        //             bottom: "-2em",
        //             left: "50%",
        //             translate: "-50%",
        //             display: "flex",
        //             gap: ".25rem",
        //         }}>
        //             {projectsData.map((_,index) => (
        //             <button className='dot-button' onClick={() =>setCurrentIndex(index)}>
        //                     {index === currentIndex ? (
        //                         <i className="fa-solid fa-circle-dot"></i>
        //                     ) : (
        //                             <i className="fa-solid fa-circle"></i>
        //                     )}
        //                 </button>
        //         ))}
        //         </div>
        //     </div>
        // </div>
        <Card id = "projects" className="mx-auto shadow carousel-card" style={{ maxWidth: '1000px'}}>
            <Card.Header className="carousel-header"><h2 className="fw-bold">Projects</h2></Card.Header>
            <Card.Title className="fs-5 custom-title">Click on a project image to open it</Card.Title>
            <Carousel>
                {projectsData.map((project) => (
                    <Carousel.Item key={project.id}>
                        <Link to={project.path} style={{ textDecoration: 'none', color: 'inherit' }}>
                            <img
                                className="d-block"
                                src={project.imageUrl}
                                alt={`Project ${project.id}`}
                                style={{ width: '100%', height: '500px', objectFit: 'cover' }}
                            />
                            <Carousel.Caption>
                                <h5>{/*{project.name} - */}{project.label} </h5>
                            </Carousel.Caption>
                        </Link>
                    </Carousel.Item>
                ))}
            </Carousel>
        </Card>
    );
}

export default Projects;