import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import "./Project3.css"

const Project3 = () => {
    return (
        // <div className='project-three'>
        //     <div className="P3header"> <h2>Genetic Palindromes Analysis</h2> </div>
        //     <Link to="/"> <i className="fa-solid fa-arrow-left-long"></i> Back to Project Gallery</Link>
        //     <div className="outer-container">
        //         <div className="image-container"> <img src="/images/project3.png" alt="Project 3 photo" /></div>
        //         <div className="inner-text-container">
        //             <p>This project, "Genetic Palindromes" was part of my Data Structures and Algorithms class. The purpose was to demonstrate use of doubly linked lists.</p>

        //             <p>The project takes in DNA sequences from a specified file, and for each, determines whether the sequence is a genetic palindrome (defined in the GitHub README), and whether any substrings of the sequence are genetic palindromes, printing them out to the terminal in order of length.</p>

        //             <p>The full project specifications are listed in the README in the attatched GitHub link below.</p>
        //         </div>
        //     </div>
        //     <a href="https://github.com/gbeelerr/Projects/tree/main/Classes/CPSC350/PA4" target="_blank" rel="noopener noreferrer"><i className="fab fa-github"></i> Open Project in Github <i className="fab fa-github"></i></a>
        // </div>
        <Card className="mx-auto shadow project-card" style={{ maxWidth: '1000px' }}>
            <Card.Header className="project-header">
                <h2>Genetic Palindromes Analysis</h2>
            </Card.Header>
            <Row>
                <Col>
                    <a href="https://github.com/gbeelerr/Projects/tree/main/Classes/CPSC350/PA4" target="_blank" rel="noopener noreferrer"><h6><i className="fab fa-github"></i> Open Project in Github<i className="fab fa-github"></i></h6> </a></Col>
            </Row>

            <Row className="align-items-center">
                <Col sm={12} lg={6}>
                    <img className="img-fluid d-block mx-auto" src="/images/project3.png" alt="Project 3 photo" />
                </Col>
                <Col sm={12} lg={6} className='px-5'>
                    <p>This project, "Genetic Palindromes" was part of my Data Structures and Algorithms class. The purpose was to demonstrate use of doubly linked lists.</p>

                      <p>The project takes in DNA sequences from a specified file, and for each, determines whether the sequence is a genetic palindrome (defined in the GitHub README), and whether any substrings of the sequence are genetic palindromes, printing them out to the terminal in order of length.</p>

                    <p>The full project specifications are listed in the README in the attatched GitHub link above.</p>
                </Col>
            </Row>
            <Row>
                <Link to="/" className="back-link"> <i className="fa-solid fa-arrow-left-long"></i> Back to Project Gallery</Link>
            </Row>
        </Card>
    );
};


export default Project3;