import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import "./Project5.css"

const Project5 = () => {
    return (
        // <div className='project-five'>
        //     <div className="P5header"> <h2>Spoons Simulator</h2> </div>
        //     <Link to="/"> <i className="fa-solid fa-arrow-left-long"></i> Back to Project Gallery</Link>
        //     <div className="outer-container">
        //         <div className="image-container"> <img src="/images/project5.png" alt="Project 5 photo" /></div>
        //         <div className="inner-text-container">
        //             <p>This project was part of my Object Oriented Programming course. It demonstrates a deep understanding of OOP by using it to create a simulation of the game Spoons</p>

        //             <p>The full project specifications on the README as well as all of the source files are linked below.</p>
        //         </div>
        //     </div>
        //     <a href="https://github.com/gbeelerr/Projects/tree/main/Classes/CPSC231/SpoonsGame" target="_blank" rel="noopener noreferrer"><i className="fab fa-github"></i> Open Project in Github <i className="fab fa-github"></i></a>
        // </div>
        <Card className="mx-auto shadow project-card" style={{ maxWidth: '1000px' }}>
            <Card.Header className="project-header">
                <h2>Spoons Simulator</h2>
            </Card.Header>
            <Row>
                <Col>
                    <a href="https://github.com/gbeelerr/Projects/tree/main/Classes/CPSC231/SpoonsGame" target="_blank" rel="noopener noreferrer"><h6><i className="fab fa-github"></i> Open Project in Github<i className="fab fa-github"></i></h6> </a>
                    </Col>
            </Row>

            <Row className="align-items-center">
                <Col sm={12} lg={6}>
                    <img className="img-fluid d-block mx-auto" src="/images/project5.png" alt="Project 5 photo" />
                </Col>
                <Col sm={12} lg={6} className='px-5'> 
                    <p>This project was part of my Object Oriented Programming course. It demonstrates a deep understanding of OOP by using it to create a simulation of the game Spoons</p>

                    <p>The full project specifications on the README as well as all of the source files are linked above.</p>

                </Col>
            </Row>
            <Row>
                <Link to="/" className="back-link"> <i className="fa-solid fa-arrow-left-long"></i> Back to Project Gallery</Link>
            </Row>
        </Card>
    );
};

export default Project5;