import React from 'react';
import './Contact.css';
import SocialBar from './SocialBar';
import ContactForm from './ContactForm';
import * as yup from 'yup';
import { Card, Row, Col } from 'react-bootstrap';

function Contact() {
    const handleSave = (values) => {
        console.log({values});
    };
    return (
        // <div id="contact" className="contact-container">
        //     <div className="contact-header"> <h1>Contact</h1></div>
        //     <div className="contact-content">
        //         <div className="grid-container">
        //             <div className="grid-item">
        //                 <p><i className="fa-solid fa-envelope"></i> Email:</p>
        //                 <p>beeler@chapman.edu</p>
        //                 <p>graedonbeeler@icloud.com</p>
        //             </div>
        //             <div className="grid-item">
        //                 <p><i className="fa-solid fa-phone"></i> Phone:</p>
        //                 <p>925-784-5807</p>
        //             </div>
        //             <div className="grid-item">
        //                 <p>Socials: </p>
        //                 <SocialBar />
        //             </div>
        //         </div>
        //         <ContactForm onSave={handleSave} />
        //     </div>
        // </div>
        <Card id="contact" className="mx-auto contact-container shadow" style={{ maxWidth: '1000px' }}>
            <Card.Header className="contact-header">
                <h2 className="fw-bold">Contact</h2>
            </Card.Header>
            <Card.Body>
                <Row>
                    <Col sm={12}>
                        <div className="grid-item">
                            <p><i className="fa-solid fa-envelope"></i> Email:</p>
                            <p>beeler@chapman.edu</p>
                            <p>graedonbeeler@icloud.com</p>
                        </div>
                        <div className="grid-item">
                            <p><i className="fa-solid fa-phone"></i> Phone:</p>
                            <p>925-784-2780</p>
                        </div>
                        <div className="grid-item">
                            <p>Socials: </p>
                            <SocialBar />
                        </div>
                    </Col>
                    {/* <Col md={6} sm={12}>
                        <ContactForm onSave={handleSave} />
                    </Col> */}
                </Row>
            </Card.Body>
        </Card>
    );
}

export default Contact;